import { ComponentProps, ReactNode } from 'react';

import { Box } from 'components/Box';

type Props = ComponentProps<typeof Box> & {
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  display?: string;
  children: ReactNode;
};

export function Hide({
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  display,
  ...props
}: Props) {
  return (
    <Box
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      display={[xs, sm, md, lg, xl].map((n) =>
        n ? 'none' : display || 'block',
      )}
    >
      {children}
    </Box>
  );
}
