import { ReactNode, useEffect, useState } from 'react';

import { Hide } from 'components/Hide';
import { ClientOnly } from 'containers/ClientOnly';
import { breakpointValues } from 'theme/theme';

const breakpointNames = ['xs', 'sm', 'md', 'lg', 'xl'];

function HideContainer({
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  mobile,
  desktop,
  noWrapper,
  ...props
}: {
  children: ReactNode;
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  mobile?: boolean;
  desktop?: boolean;
  noWrapper?: boolean;
}) {
  const [breakpoint, setBreakpoint] = useState(0);
  const hide = {
    xs: xs || mobile || false,
    sm: sm || mobile || false,
    md: md || mobile || false,
    lg: lg || desktop || false,
    xl: xl || desktop || false,
  };

  const resizeHandler = () => {
    const width = window.innerWidth;
    const newBreakpoint = [
      breakpointValues.xs,
      breakpointValues.sm,
      breakpointValues.md,
      breakpointValues.lg,
    ].reduce(
      (current, point, index) => (width >= point ? index + 1 : current),
      0,
    );
    setBreakpoint(newBreakpoint);
  };

  // @ts-expect-error TS(2345): Argument of type '() => (() => void) | null' is no... Remove this comment to see the full error message
  useEffect(() => {
    if (typeof window !== 'undefined') {
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      return () => {
        window.removeEventListener('resize', resizeHandler);
      };
    }
    return null;
  }, []);

  if (noWrapper) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return hide[breakpointNames[breakpoint]] ? null : (
      <ClientOnly>{children}</ClientOnly>
    );
  }
  return (
    <Hide
      xs={xs || mobile}
      sm={sm || mobile}
      md={md || mobile}
      lg={lg || desktop}
      xl={xl || desktop}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Hide>
  );
}

// eslint-disable-next-line import/no-default-export
export default HideContainer;
