import invariant from 'invariant';
import { useMemo } from 'react';

import { ApiFilestackImageWithTextData } from 'api/types/ApiFilestackImageData';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { isDefined } from 'utils/functional';
import { randomInt } from 'utils/functional/number/randomInt';

const makeKey = (id: string) => `placeholderImg:${id}`;

type Props = {
  postId: string;
  placeholderImages: ApiFilestackImageWithTextData[];
  alt: string;
  height: number;
  width: number;
  round?: boolean;
  expandSize?: boolean;
  'data-qa-id'?: string;
};

export function PlaceholderImage({
  postId,
  placeholderImages,
  alt,
  height,
  width,
  round,
  expandSize,
  'data-qa-id': dataQaId,
}: Props) {
  invariant(
    isDefined(localStorage),
    'Placeholder image uses local storage (browser)',
  );

  const storedHandle = localStorage.getItem(makeKey(postId));
  const randomItem = useMemo(
    () => placeholderImages[randomInt(placeholderImages.length)],
    [placeholderImages],
  );

  const image =
    placeholderImages.find((i) => i.handle === storedHandle) ||
    (() => {
      localStorage.setItem(makeKey(postId), randomItem.handle);
      return randomItem;
    })();

  return (
    <FilestackImage
      data={image}
      alt={image?.altText || alt}
      height={height}
      width={width}
      round={round}
      expandSize={expandSize}
      data-qa-id={dataQaId}
    />
  );
}
